
    .actions-button {
        color: white;
        box-shadow: none;
        border: none;
        box-sizing: border-box;
        background-color: rgb(111, 111, 111);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        opacity: 0.9;
        font-size: 15px;
        height: 30px;
        transition: all 300ms linear;
        text-decoration: none;
        padding: 0;
        width: 30px;
        margin: 5px 5px 5px 0;
      }
      
      .actions-button:hover {
        background-color: rgba(255, 255, 255, 0.05);
        transition: all 300ms linear;
      }
