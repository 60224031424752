body {
  font-family: -apple-system, BlinkMacSystemFont, 'ArialRounded', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;  
  display: flex;
  flex-direction: column;
  background-color: #191d24;
  /*overflow-y scroll to ensure there is always a verticle scroll bar even when not necessary
  this is to ensure that the page content doesn't shift horizontally when there is or isn't vertical scroll content, 
  which happens because the scroll bar is ~10px in width and factors into the pages' content positioning*/
  overflow-y: scroll;

}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

}

.App-body {
  display: flex;
  margin-top: 55px;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
