
.carousel-wrap {
    position: relative;
}

.carousel-horizontal {
    padding: 0;
}

ul{
    list-style: none;
}

.carousel-horizontal .carousel-nav .carousel-prev {
    top: 0;
    left: -40px;
}

.video-carousel-prev {
    left: -40px;
    top: 0;
    height: 300px;

    .carousel {
        height: 300px;
    }
}

.carousel-nav li {
    position: absolute;
}

.carousel-nav .carousel-next a.carousel-disabled, .carousel-nav .carousel-prev a.carousel-disabled {
    cursor: default;
}

.carousel-nav a {
    position: relative;
    display: block;
    width: 30px;
    height: 340px;
    cursor: pointer;
}

.carousel-horizontal .carousel-nav .carousel-prev a.carousel-disabled span {
    background-position: -400px -770px;
}
.carousel-horizontal .carousel-nav .carousel-prev a span {
    background-position: -310px -770px;
}

.carousel-nav a .carousel-chevron {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
    color: white;
}

.carousel-horizontal .carousel-nav .carousel-next {
    top: 0;
    right: -40px;
}
.carousel-horizontal .carousel-nav .carousel-next a span {
    background-position: -430px -770px;
}
.video-carousel-next {
    right: -40px;
    height: 300px;
    top: 0;

    .carousel {
        height: 300px;
    }
}

.carousel-mask{
    width: 100%;
    position: relative;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    scroll-behavior: smooth;
}

.carousel-mask::-webkit-scrollbar {
    display: none;
}
  
.carousel-mask::-moz-scrollbar {
    display: none;
}

.carousel {
    height: 380px;
    overflow: hidden;
    min-width: 100%;
    padding: 0;
    top: 0;
    left: 0;
}

.carousel-horizontal .carousel {
    float: left;
}

.carousel>li {
    float: left;
    font-size: 0;
    width: 470px;
    height: 261px;
}

.video-carousel-horizontal .carousel>li {
    float: left;
    display: inline;
    margin: 0 10px 0 0;
    width: 470px;
}

.carousel>li, .carousel>li a {
    display: block;
}
.carousel>li {
    position: relative;
}

.list-item {
    width: 470px;
}

@media screen and (max-width: 376px) {
    .carousel {
        height: 240px;
    }

    .carousel-nav a {
        height: 184px;
    }
    
    .carousel>li {
        width: 140px;
        height: 184px;
    }    
    .videos{
        display: none;
    }

}