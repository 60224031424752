.media-page {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    width: 100%;
    justify-content: center;
    padding-bottom: 60px;
}

.media-page-movie-content {
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.media-page-background-container {
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
}

.media-page-background {
    box-shadow: 0 0 8px 8px #2c3440 inset;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.media-page-poster-and-actions-container {
    width: 300px
}

.media-page-poster-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-right: 40px
}

.media-page-poster {
    max-height: 390px;
    width: auto;
    max-width: 260px;
    margin-top: 10px;
    box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;

}

.media-page-actions {
    display: flex;
    width: 100%;
    margin-right: 40px;
    max-height: 390px;
    margin-top: 20px;
}

.media-page-watch-providers{
    color: white;
    border-radius: 4px;

    h4 {
        margin-bottom: 10px
    }
}

.watch-providers {
    display: inline-flex;
    padding: 0;
    flex-wrap: wrap;
    width: 90%;
    margin: 0;
}

.watch-providers>li{
    margin: 5px;
    margin-left: 0;
}

.media-page-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 640px;
    color: white;
    font-weight: 400;
    // justify-content: space-around;
    box-sizing: border-box;
}

.media-page-title{
    
    h1 {
        margin: 0
    }
}

.media-page-info {
    height: 80%;
}

.movie-plot {
    height: 20%
}

.crew {
    margin-right: 15px;
}


@media screen and (max-width: 1000px) {
    .media-page{
        width: 100%;  
    }
    .media-page-header{
        width: 100%;  
    }

    .media-page-background{
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 800px) {
    .media-page-background-container {
        height: 300px;
    }
}

@media screen and (max-width: 550px) {
    .media-page-background-container {
        height: 250px;
    }
    
    .media-page-poster {
        height: 200px;
    }
}

@media screen and (max-width: 376px) {
    .media-page-header{
        height: 200px;
    }
    .media-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
    .media-page-background {
        box-shadow: 0 0 8px 8px #2c3440 inset;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .media-page-background-container {
        height: 220px;
    }


    .media-page-poster {
        height: 100px;
    }

    .media-page-actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 40px;
        max-height: 390px;
        margin-top: 20px;
    }

    .media-page-info-container {
        max-width: 280px;
    }

    .media-page-title h1 {
        font-size: 26px;
    }
}