#footer {
    background-color: white;
    border: none;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    color: rgb(36, 28, 84);
    transition: all 1500ms linear;
    padding-bottom: 10px;
  }
  
  .footer-hr {
    background-color: hsl(205, 70%, 17%);
    width: 100%;
    margin: 0px 0px 10px 0px;
    height: 2px; 
    border:0;
    padding: 0px;
  }

  .copyright {
    font-size: 15px;
    font-weight: 300;
  }