.tmdb-page {
    color: lightgray;
    width: 100%;
    max-width: 960px;
    display: flex;
    justify-content: center;

    a {
        text-decoration: none;
        color: grey;
    }
}

.tmdb-info {
    width: 50%
}