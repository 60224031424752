.person-credits {
    display: flex;
    flex-direction: column 
}

.person-page {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    width: 100%;
    justify-content: center;
    padding-bottom: 60px;
}

.person-page-movie-content {
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.person-page-background-container {
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
}

.person-page-background {
    box-shadow: 0 0 8px 8px #2c3440 inset;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.person-page-poster-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-right: 40px
}

.person-page-poster {
    max-height: 390px;
    width: auto;
    margin-top: 10px;
    box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;

}

.person-page-actions {
    display: flex;
    width: 100%;
    margin-right: 40px;
    max-height: 390px;
    margin-top: 20px;
}

.person-page-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: white;
    font-weight: 400;
    // justify-content: space-around;
    box-sizing: border-box;
}

.person-page-title{
    
    h1 {
        margin: 0
    }
}

.person-page-info {
    height: 80%;
}

.movie-plot {
    height: 20%
}

.crew {
    margin-right: 15px;
}

@media screen and (max-width: 1000px) {
    .person-page{
        width: 100%;  
    }
    .person-page-header{
        width: 100%;  
    }

    .person-page-background{
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 800px) {
    .person-page-background-container {
        height: 300px;
    }
    .person-page-poster-container {
        margin-right: 10px
    }    
}

@media screen and (max-width: 550px) {
    .person-page-background-container {
        height: 250px;
    }
    
    .person-page-poster {
        height: 200px;
    }
}

@media screen and (max-width: 376px) {
    .person-page-header{
        height: 200px;
    }
    .person-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
    .person-page-background {
        box-shadow: 0 0 8px 8px #2c3440 inset;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .person-page-background-container {
        height: 220px;
    }
    

    .person-page-poster {
        height: 100px;
        margin-right: -40px;
    }

    .person-page-actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 40px;
        max-height: 390px;
        margin-top: 20px;
    }

    .person-page-info-container {
        max-width: 280px;
        margin-left: 40px;
    }

    .person-page-title h1 {
        font-size: 26px;
    }
}