.media-card {
    width: 100%;
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;
    cursor: pointer;
    
    &:hover{
        border: 1px solid rgb(2, 2, 143)
    }

}
.actions {
    display: flex;
    width: 100%;
}

.media-card-placeholder {
    width: 100%;
    height: 342px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;
    background-color: grey;
    cursor: pointer;

    &:hover{
        border: 1px solid rgb(2, 2, 143)
    }

    h4 {
        font-size: 15px;
        color: white;
        text-align: center;
    }
}

@media screen and (max-width: 376px) {
    .media-card-container{
        height: 200px;
    }
    .media-card-link{
        height: 200px;
    }
    .media-card {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;
        cursor: pointer;
        
        &:hover{
            border: 1px solid rgb(2, 2, 143)
        }
    
    }
    .actions {
        display: flex;
        width: 100%;
    }
    
    .media-card-placeholder {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        box-shadow: rgba(0,0,0,0.1) 4px 4px 8px 0px;
        background-color: grey;
        cursor: pointer;
    
        &:hover{
            border: 1px solid rgb(2, 2, 143)
        }
    
        h4 {
            font-size: 15px;
            color: white;
            text-align: center;
        }
    }
    
}