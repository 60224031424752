.search-results-page{
    width: 960px;
    color: white;
}

#popular {
    position: relative;
    margin: 0 0 30px;
    
    h2 {
        font-size: 20px;
        font-weight: 400;
    }
}


@media screen and (max-width: 1000px) {
    .search-results-page {
        width: 100%;  
        padding: 0px 40px;
    }
}

@media screen and (max-width: 376px) {
    .search-results-page {
        width: 375px;
        padding: 0px 40px;
    }
}