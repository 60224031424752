.navigation-bar {
    display: flex; 
    justify-content: space-between ; 
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.842);
    height: 60px;
    width: 960px;    

    .home-link {
        display: flex;
        align-items: center;
        width: auto
    }

    h1 {
        font-size: larger;
        color: white;
        margin-left: 5px
    }
    .main-nav{
        display: flex;
        align-items: center;
    }

    .nav-button {
        color: white;
        box-shadow: none;
        border: none;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        opacity: 0.9;
        font-size: 15px;
        min-width: 70px;
        height: 30px;
        transition: all 500ms linear;
        text-decoration: none;
      }
      
      .nav-button:hover {
        background-color: rgba(255, 255, 255, 0.05);
        transition: all 500ms linear;
      }
      

}

@media screen and (max-width: 960px) {
    .navigation-bar {
        display: flex; 
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 5;
        background-color: rgba(0, 0, 0, 0.842);
        height: 60px;
        padding-left: 10px;
        padding-right:10px;
        width: 100%;    
    }
}

@media screen and (max-width: 376px) {
    .navigation-bar {
        display: flex; 
        // justify-content: space- ; 
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 5;
        background-color: rgba(0, 0, 0, 0.842);
        height: 60px;
        padding-left: 10px;
        padding-right:10px;
        margin-left: 50px;
        margin-right: 50px;
        width: 375px;    
    
        .home-link {
            display: flex;
            align-items: center;
            width: auto
        }
    
        h1 {
            display: none;
        }
    
        .sign-in-link {
            color: white
        }
    }
}